@import '/src/styles/import.scss';

.list-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;

  thead {
    tr {
      &.list-row-tr {
        color: $color-black-100;
        height: 56px;
        width: 100%;

        th {
          height: 56px;
          padding: 10px;
          color: $color-black-60;
          font-size: 14px;
          font-weight: 700;
          padding: 0px 20px;
          border-bottom: 2px solid $color-black-100;

          &.-left {
            text-align: left;
          }
        }
      }

    }
  }

  tbody {
    tr {
      &.-no-data {
        height: 110px;
        background-color: $color-bg-f9;
      }

      &:not(.-no-data) {
        td {
          border-bottom: 1px solid $color-black-10;
        }
      }

      td {
        height: 60px;
        padding: 0px 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: $color-black-70;

        &.link-btn {

          & a {
            visibility: hidden;
            display: block;
            height: 36px;
            line-height: 36px;
            background-color: $color-blue-100;
            border-radius: 45px;
            color: $color-white-100;
            font-weight: 500;
            min-width: 95px;
            position: relative;
            float: right;
          }
        }

        &.-left {
          text-align: left;
        }

        &.no {
          color: $color-black-20;
          font-size: 14px;
          font-weight: 700;
        }

        .icon-button {
          width: 24px;
          height: 24px;
        }

        span {
          text-wrap: nowrap;
          font-size: 14px;
        }

        &.chip {
          padding: 0px;
          font-weight: 500;

          span {
            height: 32px;
            padding: 5px 16px;
            border-radius: 16px;
            font-size: 12px;
            font-weight: 500;
          }

          &.test {
            span {
              border: 1px solid $color-text-grayea;
              background: $color-white-100;
              color: $color-text-gray99;
            }
          }

          &.on_standby {
            span {
              padding: 5px 12px;
              border: 1px solid $color-text-grayea;
              background: $color-white-100;
              color: $color-text-gray99;
            }
          }

          &.failed {
            span {
              background: $color-bg-red;
              color: $color-red-100;
            }
          }

          &.service,
          &.completed,
          &.active {
            span {
              background: $color-bg-blue;
              color: #7B8CC8;
            }
          }

          &.analyzing {
            span {
              background: $color-bg-green;
              color: $color-green-100;
            }
          }

          &.activation {
            span {
              padding: 5px 12px;
              background: $color-bg-green;
              color: $color-green-100;
            }
          }

          &.disabled {
            span {
              background: $color-bg-f9;
              color: $color-black-20;
            }
          }

          &.deleted {
            span {
              background: $color-bg-f9;
              color: $color-text-graycc;
            }
          }

        }

        &.ellipsis {
          span {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
            width: 100%;
          }
        }

        &.blue-bold {
          span {
            color: $color-blue-100;
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
          }

          &.request-name {
            span {
              cursor: default;
            }
          }
        }

        &.preline {
          white-space: pre-line;
        }

        &.task {}

        &.ver {}

        &.update {}

        &.regist {}

        &.project-name {
          span {
            &::before {
              content: url(../../assets/images/icons/icon_folder.svg);
              padding: 0px 10px 0px 0px;
              position: relative;
              top: 3px;
            }
          }
        }

        &.request-name {
          span {
            &::before {
              content: url(../../assets/images/icons/icon_image.svg);
              padding: 0px 10px 0px 0px;
              position: relative;
              top: 3px;
            }
          }
        }

        &.imagedataset-name {
          span {
            cursor: default;
          }
        }

        &.image-count {
          font-size: 14px;
          font-weight: 700;
        }
      }

      &:hover {
        background: $color-bg-blue;

        .link-btn {
          & a {
            visibility: visible;
          }

        }
      }
    }
  }
}