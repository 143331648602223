@import '/src/styles/import.scss';

.block-title-container__container {
  display: flex;
  height: 100px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: $color-white-100;
  border-bottom: 1px solid $color-black-10;

  .block-title__wrap {
    display: flex;
    // width: 1400px;
    width: 100%;
    padding: 0px 100px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;

    .left {
      display: flex;
      gap: 10px;
    }
  }
}