.map-view-control__container {
  position: absolute;
  left: 30px;
  bottom: 20px;
  z-index: 1;
  margin: 0 0 4px 4px;
  padding: 0 12px;
  height: 22px;
  line-height: 22px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  transition: .5s;


  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      float: left;
      position: relative;
      height: 22px;
    }


    .-info-1 {
      text-align: center;
      font-size: 8px;
      font-weight: bold;
      color: #3e3e3e;
      line-height: 18px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .-u-line {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 5px;
      height: 7px;
      border: 2px solid #3e3e3e;
      border-top: 0;
    }

    .-cap-1 {
      width: 8px;
    }

    .-info-2 {
      text-align: center;
      font-size: 8px;
      font-weight: bold;
      color: #3e3e3e;
      line-height: 18px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .-cap-2 {
      width: 10px;
    }

    .-info-3 {
      min-width: 100px;
      padding: 0 8px;
      text-align: center;
      font-size: 9px;
      font-family: 'Roboto';
      font-weight: bold;
      color: #3e3e3e;

      .__icon {
        margin-right: 4.75px;
        display: inline-block;
        width: 8px;
        height: 8px;
        line-height: initial !important;
        text-align: center;
        vertical-align: middle;
        background: transparent url(../../../assets/images/map/location.svg) center no-repeat;
      }
    }

    .-info-4 {
      min-width: 50px;
      text-align: center;
      font-size: 9px;
      font-family: 'Roboto';
      font-weight: bold;
      color: #3e3e3e;
    }
  }
}