@import '/src/styles/import.scss';

.login__container {
  background: $color-black-90;
  height: 100%;

  .login__body {
    display: flex;
    width: 100%;
    height: calc(100% - 72px);
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .login__wrap {
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      .input__area {
        display: flex;
        gap: 20px;
        flex-direction: column;
        position: relative;
        padding: 60px 0 0 0;
      }

      .check__area {
        padding: 0px 0 20px 0;
        align-self: flex-start;
      }

      .warning__area {
        padding: 0 0 20px 0;
        color: $color-red-100;
        font-size: 14px;
        font-weight: 500;
      }

      .forgot_pwd__area {
        padding: 20px 0;
        cursor: pointer;
        color: $color-text-darkblue;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .bottom__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 40px;

      .text-gray1 {
        color: $color-black-60;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
      }

      .text-gray2 {
        color: $color-black-40;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
      }

      img.bottom-logo {
        position: relative;
        top: 16px;
      }
    }
  }
}