@import '/src/styles/import.scss';

.content__container {
  display: flex;
  min-width: 990px;
  width: 100%;
  padding: 40px 100px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 40px;
}