@import '/src/styles/import.scss';

.control__wrap {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .label__wrap {
    display: flex;
    min-width: 200px;
    min-height: 36px;
    align-items: center;
    gap: 4px;

    img {
      cursor: pointer;
    }

    span {
      color: $color-black-70;
      font-size: 14px;
      font-weight: 500;

      &.-required {
        color: $color-red-100;
      }
    }

    .info__wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tooltip__area {
        position: absolute;
        top: 13px;
      }
    }
  }
}