@import '/src/styles/import.scss';

.image-dataset-popup__container {
  display: flex;
  width: 1200px;
  height: 720px;
  flex-direction: column;
  align-items: flex-start;

  .popup__header {
    display: flex;
    height: 48px;
    padding: 0px 12px 0px 40px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    background: $color-black-100;

    .-title {
      color: $color-black-10;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.32px;
    }

    img {
      width: 40px;
      height: 40px;
      padding: 10.881px 11.357px 10.263px 9.786px;
    }
  }

  .popup__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    .filter__wrap {
      display: flex;
      height: 56px;
      padding: 0px 20px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      background: $color-bg-f1;

      .-period {
        display: flex;
        align-items: center;
        gap: 10px;

        span.period-span {
          color: $color-black-70;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
        }
      }
    }

    .content__wrap {
      display: flex;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;

      .list__area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;

        .-list-header {
          display: flex;
          height: 42px;
          align-items: center;
          align-self: stretch;
          background: $color-black-10;

          .-space {
            display: flex;
            width: 36px;
          }

          .-col {
            display: flex;
            align-items: center;
            gap: 4px;
            align-self: stretch;
            position: relative;

            span {
              color: $color-black-60;
              font-size: 12px;
              font-weight: 700;
              letter-spacing: -0.24px;

              &.-create-date {
                padding: 0px 10px;
                text-align: end;
              }
            }
          }
        }

        .-list__wrap {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
          background: $color-white-100;
          width: 720px;
          max-height: 536px;
          overflow-y: auto;
        }
      }

      .map__area {
        width: 680px;
        height: 100%;
        background: $color-white-100;
        position: relative;

        .map {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .popup__bottom {
    display: flex;
    padding: 15px 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: $color-white-100;
    border-top: 1px solid $color-black-100;

    .-image-data-set-btn {
      span {
        &.-count {
          color: $color-blue-100;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: -0.24px;
        }

        &.-disabled {
          color: $color-black-30;
        }
      }
    }
  }
}