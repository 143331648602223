@import '/src/styles/import.scss';

.reset-password__body {
  display: flex;
  width: 580px;
  padding: 24px 36px 40px 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;

  .-title {
    color: $color-black-70;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px;
  }
}