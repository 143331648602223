@import '/src/styles/import.scss';

.list-item {
  display: flex;
  height: 42px;
  padding: 12px;
  justify-content: space-between;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;
  cursor: pointer;
  position: relative;

  img {
    transform: rotate(90deg);
    position: absolute;
    padding: 12px;
    right: 0px;
    top: 0px;
  }

  &.-checked {
    background: $color-black-90;

    label {
      color: $color-white-100;
    }

    img {
      // content: url(../../../../../assets/images/icons/triangle/icon_triangle_right_white.svg);
      content: url(../../../../assets/images/icons/triangle/icon_triangle_right_white.svg)
    }
  }

  &:hover {
    background: $color-black-90;

    label {
      color: $color-white-100;
    }

    img {
      content: url(../../../../assets/images/icons/triangle/icon_triangle_right_white.svg);
    }
  }

  .-arrow {
    &.-rotate {
      transform: rotate(270deg);
    }
  }
}

.list-content {
  display: flex;
  padding-left: 36px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: $color-bg-f9;

  .list-row {
    display: flex;
    padding: 10px 0px;
    align-items: center;
    align-self: stretch;

    span {
      color: $color-black-30;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.22px;

      &.-create-date {
        padding: 0px 10px;
        text-align: end;
      }
    }
  }
}