@import '/src/styles/import.scss';

.uikit__container {
  padding: 10px;

  .uikit__wrap {
    display: flex;
    gap: 15px;
    padding: 10px;

    .component__area {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border: 2px dashed #9747FF;

      .-body {
        display: flex;
        gap: 10px;
        padding: 15px;

        .-col {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }
}