@import '/src/styles/import.scss';

.search__right_wrap {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 10px;
  right: 0;

  form {
    display: flex;
    gap: 10px;
  }
}