@import '/src/styles/import.scss';

.sidebar__container {
  display: flex;
  width: 68px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  background: $color-black-90;

  .button__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .-bar {
      width: 68px;
      height: 1px;
      background: $color-black-100;
    }

    .-icon__wrap {
      display: flex;
      width: 68px;
      height: 68px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .-icon {
        display: flex;
        width: 36px;
        height: 36px;
        padding: 6px 4px 7px 5px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }


    }
  }
}