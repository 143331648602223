@import '/src/styles/import.scss';

table.table-project-detail,
table.table-aicore-detail,
table.table-company-detail {
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;

  thead {
    tr {
      &.list-row-tr {
        color: $color-black-100;
        height: 56px;
        width: 100%;

        th {
          height: 56px;
          padding: 10px;
          color: $color-black-60;
          font-size: 14px;
          font-weight: 700;
          padding: 0px 20px;
          border-bottom: 2px solid $color-black-100;

          &.-left {
            text-align: left;
          }

          span {
            &.-required {
              color: $color-red-100;
            }
          }

          .tr__wrap {
            display: flex;
            gap: 4px;

            .info__wrap {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .tooltip__area {
                position: absolute;
                top: 13px;
              }
            }
          }

        }
      }
    }
  }

  tbody {
    tr {

      &.version-row-tr,
      &.member-row-tr {
        td {
          height: 60px;
          padding: 0px 20px;
          text-align: center;
          border-bottom: 1px solid $color-black-10;
          color: $color-black-70;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.28px;

          &.-version {
            color: $color-black-20;
            font-size: 14px;
            font-weight: 700;
          }

          &.-index {
            text-align: center;
            color: $color-black-20;
            font-size: 14px;
            font-weight: 700;
          }

          &.-email {
            text-align: left;

            .email__wrap {
              display: flex;
              justify-content: space-between;
              align-items: center;

              span {}
            }
          }

          &.-note {
            text-align: left;
            padding: 20px;

            span {
              // display: block;
              display: flex;
              flex-direction: column;
              gap: 5px;
            }
          }

          span {
            &.-deleted {
              color: $color-black-50;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      &.member-row-tr {
        td {
          text-align: left;
        }
      }

      &.-warning {
        td:not(.-index) {
          padding: 12px 20px;
          align-content: start;
        }
      }
    }

    tr.-no-data {
      td {
        height: 110px;
        background-color: $color-bg-f9;
        color: $color-black-40;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        padding: 0px;
      }
    }
  }
}