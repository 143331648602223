@import '/src/styles/import.scss';

.draw-control__container {
  position: absolute;
  right: 22px;
  top: 20px;
  z-index: 1;
  transition: .5s;

  .draw-btn {
    width: 40px;
    height: 40px;

    &.draw-off {
      background: transparent url(../../../assets/images/map/draw_polygon_off.svg) center no-repeat;
    }

    &.draw-on {
      background: transparent url(../../../assets/images/map/draw_polygon_on.svg) center no-repeat;
    }
  }

  .tooltip__area {
    position: absolute;
    right: calc(100% + 16px);
    transform: translateY(-50px);
  }
}

.draw-dim {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .1);
  top: 0;
  left: 0;
}