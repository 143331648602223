@import '/src/styles/import.scss';

.popup__container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid $color-black-20;
  background: $color-white-100;

  &.-alert {
    width: 440px;

    .popup__header {
      height: 72px;
      align-self: stretch;
      justify-content: right;
      border: none;
    }

    .popup__body {
      width: 100%;
    }

    .popup__bottom {
      border: none;
    }
  }

  .popup__header {
    display: flex;
    width: 100%;
    height: 80px;
    padding: 0px 12px 0px 40px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-black-100;

    .-header-text {
      color: $color-black-90;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .popup__bottom {
    display: flex;
    width: 100%;
    height: 112px;
    padding: 24px 0 36px 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-top: 1px solid $color-black-100;
  }
}

.list__area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  .-list-header {
    display: flex;
    height: 42px;
    align-items: center;
    align-self: stretch;
    background: $color-black-10;

    .-space {
      display: flex;
      width: 36px;
    }

    .-col {
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      position: relative;

      span {
        color: $color-black-60;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.24px;

        &.-create-date {
          padding: 0px 10px;
          text-align: end;
        }
      }

      .tooltip__wrap {
        display: flex;
        align-items: center;
        gap: 4px;
        // position: absolute;

        .tooltip__area {
          position: absolute;
          right: -4px;
          top: 25px;

          // transform: translateX(-16px);
        }
      }

    }
  }

  .-list__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    background: $color-white-100;
    width: 720px;
    max-height: 489px;
    overflow-y: auto;
  }
}

.idf-select__body,
.member-select__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 40px;
  gap: 12px;

  .select-top__area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .label__wrap {
      display: flex;
      height: 36px;
      align-items: center;
      gap: 4px;

      span {
        color: $color-black-70;
        font-size: 16px;
        font-weight: 700;

        &.-count {
          color: $color-black-40;
        }

        &.-blue {
          color: $color-blue-100;
        }
      }
    }

    .button__wrap {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .select-content__area {
    display: flex;
    align-items: flex-start;
    align-self: stretch;

    .path-list__container {
      display: flex;
      width: 220px;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid $color-black-40;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;

      &.-pathonly {
        width: 400px;
        border-radius: 4px;

        img.-arrow {
          display: none;
        }
      }

      &.-member {
        width: 563px;
        border-radius: 4px;
      }

      .title__wrap {
        display: flex;
        height: 42px;
        padding: 0px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        background: $color-black-10;
        mix-blend-mode: multiply;
        color: $color-black-60;
        font-size: 16px;
        font-weight: 700;
      }

      .list__wrap {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        flex-direction: column;
        height: 336px;
        max-height: 336px;
        overflow-y: auto;


      }
    }
  }
}