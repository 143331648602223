@import '/src/styles/import.scss';

.alert__body {
  display: flex;
  height: 80px;
  padding: 0px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  span {
    color: $color-black-70;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    display: flex;
  }
}