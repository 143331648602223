@import '/src/styles/import.scss';

.account-password__container {
    display: flex;
    flex-direction: column;
    background: $color-black-90;
    height: 100vh;

    .account-password__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;

        .form__wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 60px;
            width: 360px;

            .title__area {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 24px;

                .-title {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 28px;
                    letter-spacing: -0.4px;
                    color: $color-black-10;
                }
            }

            .input__area {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;


                .info__area {
                    ul {
                        padding-inline: 18px;
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        list-style-type: disc;
                        color: $color-black-60;
                        font-weight: 500;
                        line-height: 14px;
                        font-size: 12px;
                        letter-spacing: -0.24px;
                    }
                }
            }

            .message_area {
                min-height: 20px;
                color: $color-red-100;
                line-height: 18px;
                letter-spacing: -0.28px;
                font-size: 14px;
                text-align: center;
            }
        }
    }

    .footer__area {
        margin: 24px 0;
        display: flex;
        justify-content: center;
    }


}