@import '/src/styles/import.scss';

.block_title_button__wrap {
  display: flex;
  gap: 10px;
}

.request-detail-progress__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  .select-box__wrap {
    display: flex;
    gap: 10px;
  }

  .options__wrap {
    display: flex;
    padding: 10px 20px;
    align-items: flex-start;
    align-self: stretch;
    background: $color-bg-f9;

    .options__area {}
  }
}

.request-image-data-set-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .text-area {
    display: flex;
    gap: 4px;

    span {
      color: $color-black-70;
      font-size: 16px;
      font-weight: 700;

      &.-gray {
        color: $color-black-40;
      }

      &.-blue {
        color: $color-blue-100;
      }
    }
  }
}

.request-image-data-set-split-line {
  height: 1px;
  align-self: stretch;
  background: $color-black-10;
  margin: 32px -40px 0 -40px;
  width: calc(100% + 80px);
}

.request-image-data-set-table__area {
  width: 100%;
  padding: 16px 0 0 0;
}