@import '/src/styles/import.scss';

button.button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  letter-spacing: -0.24px;
  box-sizing: content-box;

  &:disabled {
    cursor: default;
  }

  &.size120 {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  &.size80 {
    display: inline-flex;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
  }

  &.size45 {
    border-radius: 3px;
    padding: 0px 12px;
  }

  &.-icon {
    padding: 0px 12px 0px 8px;
  }

  &.blue {
    background: $color-blue-100;
    color: $color-bg-f9;

    &:hover {
      background: #5A92FF;
      color: $color-white-100;
    }

    &:active {
      background: #2548C4;
      color: $color-black-40;
    }

    &:disabled {
      background: #D3D6DA;
      color: $color-white-100;
    }
  }

  &.black {
    background: $color-black-100;
    color: $color-black-10;

    &:hover {
      background: $color-black-90;
      color: $color-white-100;

      img {
        filter: brightness(200%);
      }
    }

    &:active {
      background: #000000;
      color: $color-black-40;

      img {
        filter: brightness(50%);
      }
    }

    &:disabled {
      background: $color-black-10;
      color: $color-white-100;

      img {
        filter: brightness(200%);
      }
    }
  }

  &.white {
    border: 1px solid $color-black-80;
    color: $color-black-70;

    &:hover {
      border: 1px solid $color-black-100;
      color: $color-black-100;

      img {
        filter: brightness(50%);
      }
    }

    &:active {
      color: $color-black-40;

      &.size120 {
        border: 1px solid $color-black-40;
      }

      &.size80 {
        border: 1px solid $color-black-60;
      }

      img {
        filter: brightness(200%) contrast(80%);
      }
    }

    &:disabled {
      border: 1px solid $color-black-10;
      color: $color-black-10;

      &:active {
        border: 1px solid $color-black-10;
      }

      img {
        filter: brightness(500%) contrast(60%);
      }
    }
  }

  &.red {
    border: 1px solid $color-red-100;
    color: $color-red-100;

    &:hover {
      border: 1px solid $color-red-100;
      color: $color-red-100;
    }

    &:active {
      border: 1px solid #AE4A4A;
      color: #AE4A4A;
    }

    &:disabled {
      border: 1px solid $color-black-10;
      color: $color-black-10;
    }
  }

  img {
    width: 18px;
    height: 18px;
  }
}