@import '/src/styles/import.scss';

.textarea__container {
  border: 1px solid $color-text-gray99;
  background: $color-white-100;
  color: $color-text-gray44;
  position: relative;
  display: flex;

  .textarea__area {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 12px;


    textarea {
      border: none;
      outline: none;
      resize: none;
      width: 100%;
      height: calc(100% - 14px);

      &::placeholder {
        color: $color-text-graycc;
      }
    }

    .length__container {
      height: 14px;
      text-align: right;
      display: flex;
      justify-content: right;

      span {
        font-size: 10px;

        &.-empty {
          font-weight: 400;
          color: $color-text-grayea;
        }

        &.-bold {
          color: $color-text-gray44;
          font-weight: 700;
        }

        &.-normal {
          color: $color-text-gray99;
          font-weight: 400;
        }
      }
    }
  }
}