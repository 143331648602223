@import '/src/styles/import.scss';

.header__container {
  display: flex;
  width: 100%;
  height: 72px;
  padding: 19px 39px 20px 20px;
  align-items: center;
  background: #1B1D21;
  justify-content: space-between;

  .-logo {
    cursor: pointer;
  }

  .menu__wrap {
    display: flex;
    align-items: center;
    gap: 40px;

    .-menu {
      color: $color-black-40;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;

      &.-clicked {
        color: $color-black-10;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}