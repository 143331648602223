@import '/src/styles/import.scss';

.input__container {
  display: flex;
  gap: 6px;
  justify-content: center;
  flex-direction: column;

  &.width-100 {
    width: 100%;
  }

  .input-label {
    display: flex;
    padding: 0px 12px;
    align-items: center;
    justify-content: start;
    gap: 10px;
    position: relative;
    width: 100%;

    &.bright {
      border: 1px solid $color-text-gray99;
      background: $color-white-100;

      &:hover {
        border: 1px solid $color-text-gray44;
      }

      &.-readOnly {
        border: 1px solid $color-text-graycc;
        background: $color-bg-f9;
      }

      &:focus-within:not(.-readOnly, .-warning) {
        border: 1px solid $color-text-gray44;
        background: $color-white-100;
      }

      &.-disabled {
        border: 1px solid $color-text-graycc;
        background: $color-bg-f9;

        input {
          background: transparent;
        }
      }

      &.-warning {
        border: 1px solid $color-red-100;

        .length__container {
          span {
            &.-current {
              color: $color-red-100;
            }
          }
        }
      }

      &.-editable {
        border: 1px solid $color-black-10 !important;
        width: 100% !important;
      }

      input {
        width: 100%;
        color: $color-text-gray44;
        font-size: 14px;
        font-weight: 400;
        outline: none;
        border: none;
        letter-spacing: -0.28px;

        &::placeholder {
          color: $color-text-graycc;
        }
      }

      .length__container {

        span {
          font-size: 10px;
          color: $color-text-gray44;

          &.-empty {
            font-weight: 400;
            color: $color-text-grayea;
          }

          &.-bold {
            font-weight: 700;
          }

          &.-normal {
            font-weight: 400;
          }
        }
      }
    }

    &.dark {
      border: 1px solid $color-black-60;
      background: $color-black-80;

      &:hover {
        border: 1px solid $color-black-40;
      }

      &.-readOnly {
        border: 1px solid $color-text-graycc;
        background: $color-bg-f9;
      }

      &:focus-within:not(.-readOnly) {
        border: 1px solid $color-black-40;
        background: $color-black-80;
      }

      &.-disabled {
        border: 1px solid $color-black-70;
        background: $color-dark-dimed;
      }

      &.-warning,
      &.-warning:focus-within {
        border: 1px solid $color-red-100;

        .length__container {
          span {
            &.-current {
              color: $color-red-100;
            }
          }
        }
      }

      &.-editable {
        border: 1px solid $color-black-10 !important;
        width: 100% !important;
      }

      input {
        width: 100%;
        color: $color-black-10;
        font-size: 14px;
        font-weight: 400;
        outline: none;
        border: none;
        background: $color-black-80;
        letter-spacing: -0.28px;

        &::placeholder {
          color: $color-black-60;
        }

        &:disabled {
          background: $color-dark-dimed;
          color: $color-black-60;
        }

        &:-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0px 1000px $color-black-80 inset;
          -webkit-text-fill-color: $color-black-10;
          caret-color: white;
        }
      }


    }
  }

  span.-warning-text {
    top: calc(100% + 6px);
    left: 0px;
    color: $color-red-100;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.22px;
    white-space: pre-line;
  }
}