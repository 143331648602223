@import '/src/styles/import.scss';

.project-information__container,
.company-information__container,
.aicore-information__container {
  .control_period__wrap {
    display: none;
    height: 100%;
    align-items: flex-start;
    gap: 20px;

    &.show {
      display: flex;
    }
  }

  .right-btn__area {
    .btn-comp {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
    }

    display: flex;
    align-items: center;
    gap: 20px;
  }

  .control_logo__wrap {
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;

    .filebox {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      .input__area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 6px;

        .warning-msg {
          color: $color-red-100;
          font-size: 11px;
          font-weight: 500;
        }

        ul {
          li.text-li {
            display: flex;
            color: $color-black-30;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.28px;
            line-height: 20px;

            .-dot {
              -webkit-border-radius: 10px;
              border-radius: 10px;
              min-width: 3px;
              height: 3px;
              margin: 0px 7px;
              background-color: $color-black-30;
              position: relative;
              top: 9px;
            }
          }
        }
      }

      input[type="file"] {
        position: absolute;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        border: 0;
      }
    }

    .image__area {
      height: 72px;
      padding: 10px 20px;
      box-sizing: border-box;
      background: $color-black-100;

      img {
        // max-width: 320px;
        height: 100%;
        display: block;
        width: auto;
      }
    }
  }

  .split-line {
    height: 1px;
    align-self: stretch;
    background: $color-black-10;
    margin: 16px 0px 0px 0px;
  }

  .control_logo_detail__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;

    .image__area {
      // padding: 20px;
      // min-width: 360px;
      background: $color-black-100;
      height: 72px;
      padding: 10px 20px;
      box-sizing: border-box;

      img {
        // max-width: 320px;
        height: 100%;
        display: block;
        width: auto;
      }
    }
  }

  span.info-span {
    color: $color-black-70;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    cursor: pointer;

    &.url-color {
      color: $color-blue-100;
    }

    &.period {
      display: none;

      &.show {
        display: flex;
      }
    }
  }

  span.description {
    color: $color-black-70;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .company-setting {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    span {
      color: $color-black-70;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.28px;
    }
  }

  .-image-data-set-btn {
    span {
      &.-count {
        color: $color-blue-100;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.24px;
      }
    }
  }

  .-image-data-set {
    display: flex;
    height: 36px;
    align-items: center;
    gap: 4px;

    span {
      color: $color-black-70;
      font-size: 14px;
      font-weight: 400;
      line-height: 36px;

      &.-count {
        color: $color-blue-100;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: -0.24px;
      }
    }
  }


  .url-form {
    display: flex;
    align-items: start;
    gap: 10px;
    color: var(--EEBLACK-EEBLACK-7, #474F60);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;

    .url {
      display: flex;
      align-items: center;
      height: 36px;
    }
  }
}