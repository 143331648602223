@import '/src/styles/import.scss';

.modal__container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background: rgba(0, 0, 0, .7);
  width: 100%;
  height: 100%;

  .modal__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}