@import '/src/styles/import.scss';

.chip_button__container {
  display: inline-flex;
  height: 32px;
  padding: 0px 16px;
  align-items: center;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;

  &.-icon {
    padding: 0px 8px 0px 16px;
  }

  &.-text-gray {
    color: $color-black-60;
  }

  &.-text-green {
    color: $color-green-100;
  }

  &.-text-white {
    color: $color-white-100;
  }

  &.-text-black {
    color: $color-black-70;
  }

  &.black {
    background: $color-black-90;
  }

  &.blue {
    background: $color-blue-100;
  }

  &.gray {
    background: $color-black-50;
  }

  &:disabled {
    cursor: default;
    background: $color-black-10;
  }
}