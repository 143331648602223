@import '/src/styles/import.scss';

.layout__container {
  width: 100%;
  height: 100%;
}

.body__wrap {
  display: flex;
  width: 100%;
  height: calc(100% - 72px);

  .content_list__container {
    display: flex;
    padding: 0px 80px;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    overflow: auto;

    .content__wrap {
      width: 100%;
      padding: 0px 100px 30px 100px;
      min-width: 990px;
      box-sizing: border-box;
    }
  }

  .content_detail__container {
    width: 100%;
    background: $color-bg-f9;
    overflow-y: auto;

    .content__wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}