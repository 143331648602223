@import '/src/styles/import.scss';

.radio__container {
  display: flex;
  align-items: center;
  gap: 10px;

  input.-radio {
    appearance: none;
    border: 1.5px solid $color-black-60;
    border-radius: 50%;
    width: 16px;
    height: 16px;

    &:checked {
      border: 5px solid $color-blue-100;

      &:disabled {
        background: radial-gradient(circle, $color-black-20 50%, $color-black-10 40%);
      }
    }

    &:disabled {
      border: 2px solid $color-black-20;
      background: $color-black-10;
    }
  }

  .-label {
    color: $color-black-70;
    font-size: 14px;
    font-weight: 500;

    &.-checked {
      color: $color-black-90;
    }

    &.-disabled {
      color: $color-black-20;
    }
  }
}