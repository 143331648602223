@import '/src/styles/import.scss';

.info-control__wrap {
  display: flex;
  padding: 0px 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  .-name {
    display: flex;
    min-width: 120px;
    height: 36px;
    align-items: center;
    gap: 4px;
    color: $color-black-40;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
  }

  .-value {
    display: flex;
    padding: 0px 12px;
    align-items: center;
    align-self: stretch;
    background: $color-bg-f9;
    color: $color-black-70;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
    width: 100%;
  }
}