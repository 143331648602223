@import '/src/styles/import.scss';

.checkbox__container {
  display: inline-flex;
  align-items: center;
  // width: 100%;
  gap: 10px;
  flex-shrink: 0;
  position: relative;

  input.-checkbox {
    appearance: none;
    min-width: 16px;
    min-height: 16px;
    border: 1.5px solid $color-black-60;
    cursor: pointer;

    &:checked {
      border: 1.5px solid $color-blue-100;
      background: url(../../assets/images/icons/check/icon_check_blue.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;

      &:disabled {
        border: 1.5px solid $color-black-20;
        background: url(../../assets/images/icons/check/icon_check_gray.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
      }
    }

    &:disabled {
      border: 1.5px solid $color-black-20;
      background: $color-black-10;

      &+label {
        color: var(--EEBLACK-EEBLACK-2, #B6BAC3);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  label.-label {
    width: -webkit-fill-available;
    color: $color-black-70;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    &.-bold {
      color: $color-black-90;
    }

  }

  &.dark {
    label.-label {
      color: $color-black-30;
    }
  }

  .-tooltip {
    position: absolute;
    top: 120%;
    left: 26px;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: $color-black-70;
    background: #F0F4FE;
    box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.20);
    z-index: 15;
    color: $color-black-70;
    font-size: 14px;
    font-weight: 400;
    width: max-content;
  }
}