@import '/src/styles/import.scss';

.zoom-control__container {
  position: absolute;
  right: 22px;
  bottom: 20px;
  z-index: 1;
  transition: .5s;

  &.calendar-open {
    bottom: 206px;
  }

  .-plus,
  .-minus {
    line-height: 0;
  }

  .-plus {
    .-plus__button {
      width: 28px;
      height: 28px;
      margin: 0;
      padding: 0;
      border: 1px solid #e3e3e3;
      background: #fff url(../../../assets/images/map/zoom_plus.svg) center no-repeat;
      background-size: 12px;
      border-radius: 3px 3px 0 0;
      cursor: pointer;

      &.disabled {
        background-color: #d8dadc;
      }
    }
  }

  .-bar {
    width: 100%;
    min-height: 100px;
    background: #fff;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    padding: 11px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .-content {
      color: #333333;
      width: 16px;
      border: 0;
      background: transparent url(../../../assets/images/map/zoom_background.png) top center repeat-y;
      background-size: 16px 9px;
      height: 100px;
      position: relative;
      text-align: left;
      font-size: 1em;

      .-range {
        width: 2px;
        margin: 0 7px;
        border: 0;
        background-image: linear-gradient(-180deg, #0078d4 0%, #00ffff 100%);
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 1;
        font-size: .7em;
        display: block;
        background-position: 0 0;
      }

      .-handle {
        width: 12px;
        height: 12px;
        border: 2px solid #55595c;
        background: #fff;
        border-radius: 50%;
        outline: none;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: -6px;
        position: absolute;
        z-index: 2;
        margin-left: 0;
      }
    }
  }

  .-minus {
    .-minus__button {
      width: 28px;
      height: 28px;
      margin: 0;
      padding: 0;
      border: 1px solid #e3e3e3;
      background: #fff url(../../../assets/images/map/zoom_minus.svg) center no-repeat;
      background-size: 12px;
      border-radius: 0 0 3px 3px;
      cursor: pointer;

      &.disabled {
        background-color: #d8dadc;
      }
    }
  }
}