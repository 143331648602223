@import '/src/styles/import.scss';

.content__box {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid $color-black-10;
  background: $color-white-100;
}