 @import '/src/styles/import.scss';

 .select__container {
   position: relative;
   border: 1px solid $color-black-40;
   background: $color-white-100;

   &:has(button.select__button:disabled) {
     border: 1px solid $color-black-20;
     background: $color-bg-f9;

     span {
       color: $color-black-20;
       background: transparent;
     }
   }

   &.border-bold {
     border: 1px solid $color-black-70;
   }

   &:hover:not(:has(button.select__button:disabled)) {
     border: 1px solid $color-black-90;

     .-select-list {
       border-right: 1px solid $color-black-90;
       border-bottom: 1px solid $color-black-90;
       border-left: 1px solid $color-black-90;
     }
   }


   button.select__button {
     display: flex;
     width: 100%;
     height: 36px;
     padding: 16px 12px;
     align-items: center;
     gap: 10px;
     flex-shrink: 0;
     // background: $color-white-100;
     justify-content: space-between;

     &:disabled {
       cursor: default;

     }

     span {
       color: $color-black-70;
       font-size: 14px;
       font-weight: 500;
       letter-spacing: -0.28px;

       &.-placeholder {
         color: $color-black-20;
       }
     }

     img {
       width: 10px;
       height: 5px;
     }
   }

   .-select-list {
     display: flex;
     position: absolute;
     width: 100%;
     box-sizing: content-box;
     transform: translateX(-1px) translateY(1px);
     flex-direction: column;
     justify-content: center;
     align-items: flex-start;
     border-right: 1px solid $color-black-40;
     border-bottom: 1px solid $color-black-40;
     border-left: 1px solid $color-black-40;
     background: #fff;
     box-shadow: 0px 2px 5px 0px rgba(204, 204, 204, 0.6);
     z-index: 1;

     ul {
       padding: 0;
       width: 100%;
       height: 100%;
       max-height: 146px;
       overflow-y: auto;

       li {
         width: 100%;
         list-style: none;
         margin: 0;
         padding: 0;
         text-align: initial;
         display: flex;
         align-items: center;
         gap: 10px;
         align-self: stretch;
         height: 36px;
         cursor: pointer;

         &:hover:not(.disabled) {
           background: $color-bg-blue;

           .icon__area {
             display: block;
           }
         }

         &.disabled {
           cursor: default;

           .-select-item {
             span {
               color: $color-black-10;
             }
           }
         }

         .-add-option__area {
           width: 100%;
           padding: 0px 12px;
         }

         .-select-item {
           padding: 9px 12px;
           width: 100%;
           height: 100%;
           color: $color-black-40;
           font-size: 14px;
           font-weight: 500;
           line-height: 18px;
           letter-spacing: -0.28px;
           text-align: start;

           &.-add-item {
             // background-color: $color-bg-blue;

             span {
               color: $color-black-40;
             }
           }

           span {
             color: $color-black-70;
             font-size: 14px;
             font-weight: 500;

             &.-bold {
               color: $color-blue-100;
               font-weight: 700;
             }
           }
         }

         .icon__area {
           display: none;
           padding: 0 12px;

           button.-edit-icon {
             display: flex;
             width: 24px;
             height: 24px;
             padding: 3px;
             justify-content: center;
             align-items: center;
             border-radius: 3px;
             border: 1px solid $color-black-20;
             background: $color-white-100;
           }
         }
       }
     }

   }

 }