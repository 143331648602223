@import '/src/styles/import.scss';

span.highlight__wrap {
  display: flex;
  color: $color-black-70;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;

  .highlight {
    color: $color-blue-100;
    font-weight: 600;
  }
}