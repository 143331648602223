@import '/src/styles/import.scss';

.filter__container {
  position: relative;

  .button__container {
    display: inline-flex;
    height: 36px;
    padding: 0px 12px 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border: 1px solid $color-black-40;
    background: $color-white-100;
    cursor: pointer;
    z-index: 1;

    &:hover {
      border: 1px solid $color-black-90;

      .title__wrap {
        span {
          color: $color-black-40;
        }
      }
    }

    &.selected {
      border: 1px solid $color-black-90;

      .title__wrap {
        span {
          color: $color-black-100;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    &.opened {
      border: 1px solid $color-black-10;
      background: $color-black-90;

      .title__wrap {
        span {
          color: $color-black-40;

          &.-total-count {
            color: $color-black-40;
          }
        }
      }
    }

    &.disabled {
      border: 1px solid $color-black-20;
      background: $color-bg-f9;

      .title__wrap {
        span {
          color: $color-black-20;
        }
      }
    }

    .title__wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      span {
        color: $color-black-50;
        font-size: 14px;
        font-weight: 500;

        &.-total-count {
          height: 18px;
          padding: 0px 6px;
          border-radius: 9px;
          background: $color-black-90;
          color: $color-white-100;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          margin-left: 8px;
        }
      }
    }
  }

  .filter_content__container {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    display: inline-flex;
    padding: 12px 24px 28px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid $color-black-10;
    background: $color-white-100;
    z-index: 1;

    /* drop_shadow */
    box-shadow: 0px 3px 15px 0px rgba(153, 153, 153, 0.40);

    .content__header {
      display: flex;
      height: 36px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      gap: 20px;

      .title__wrap {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          color: $color-black-90;
          font-size: 16px;
          font-weight: 700;

          &.-total-count {
            height: 18px;
            padding: 0px 6px;
            border-radius: 9px;
            background: $color-black-90;
            color: $color-white-100;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
          }
        }
      }

      .button__wrap {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        align-content: flex-start;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }

    .content__area {
      display: flex;
      align-items: flex-start;

      .filter__grid {
        display: grid;
        grid-auto-flow: column;
      }
    }
  }
}