@import '/src/styles/import.scss';

.tooltip__wrap {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // max-width: 434px;
  min-width: 48px;

  &.left {
    img {
      transform: rotate(180deg) translateX(-2px);
    }
  }

  &.top {
    flex-direction: column;

    .tooltip-box {
      position: fixed;
      transform: translateY(calc(50% + 7px));
    }

    img {
      transform: rotate(270deg) translateX(-4px);
    }
  }

  &.bottom {
    flex-direction: column;

    img {
      transform: rotate(90deg) translateX(-4px);
    }
  }

  .tooltip-box {
    z-index: 2;
    padding: 8px 12px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.80);
    color: $color-black-10;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
    width: max-content;
    box-sizing: content-box;
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);
    margin-right: -1px;
    white-space: pre-line;
  }
}