@import '/src/styles/import.scss';

.sub-title-container__container {
  display: flex;
  height: 36px;
  padding: 0px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;

  .sub-title__wrap {
    display: flex;
    align-items: center;
    gap: 4px;

    img {
      padding: 3px;
    }

    .-required {
      color: $color-red-100;
    }

    .-length {
      color: $color-blue-100;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.32px;
    }
  }
}