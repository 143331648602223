@import '/src/styles/import.scss';

.header__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  .total__wrap {
    display: flex;
    align-items: center;
    gap: 5px;

    .-total {
      color: $color-black-70;
      font-size: 14px;
      font-weight: 700;
    }

    .-num {
      color: $color-blue-100;
      font-size: 14px;
      font-weight: 700;
    }
  }
}