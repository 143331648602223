@import '/src/styles/import.scss';

.h2 {
  color: $color-black-90;
  font-size: 20px;
  font-weight: 700;
}

.h3 {
  color: $color-black-70;
  font-size: 16px;
  font-weight: 700;
}