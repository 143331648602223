@import '/src/styles/import.scss';

.chip__container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding-top: 24px;
  flex-flow: wrap;

  .-chip {
    display: flex;
    height: 36px;
    padding: 0px 8px 0px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 18px;
    background: $color-black-100;
    color: $color-black-10;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;

    img {
      cursor: pointer;
    }
  }
}