@import '/src/styles/import.scss';

@font-face {
  font-family: 'Pretendard Variable';
  font-weight: 45 920;
  font-style: normal;
  font-display: swap;
  src: url('../assets/font/PretendardVariable.woff2') format('woff2-variations');
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  user-select: none;
}

body {
  height: 100vh;

  #__next {
    height: 100%;

    &> :first-child {
      height: 100%;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;

  .ps__thumb-y {
    width: 6px !important;
  }
}

img {
  -webkit-user-drag: none;
}

button {
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
}

ul {
  list-style: none;
}

/* ----------- 스크롤바 ------------- */
::-webkit-scrollbar {
  width: 6px;
  /* 가로축 스크롤바 폭 너비 */
  height: 8px;
}

/* 스크롤바 막대 */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(14, 14, 14, 0.30);
  height: 50px;
}

/* 스크롤이 움직이는 뒷 배경 */
::-webkit-scrollbar-track {
  background: transparent;
  /* 스크롤바 뒷 배경 색상 */
}

.ol-overlaycontainer-stopevent {
  display: none;
}

#map {
  position: relative;
}

.-guide-cursor {
  position: absolute;
  border-radius: 4px;
  opacity: 0.8;
  background: #000;
  padding: 4px 8px;
  color: $color-black-10;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.24px;
  min-width: 126px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}