@import '/src/styles/import.scss';

.container {
  width: auto;
  position: relative;
  text-align: left;
  cursor: pointer;

  svg {
    display: none;
  }

  .ico {
    position: absolute;
    display: inline-block;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 20;
    width: 20px;
    height: 20px;
    padding: 0.125rem;
    pointer-events: none;

    &.-calendar {
      background: url('/assets/images/icons/icon_datepicker_calendar.svg') no-repeat;
      background-position: center;
      background-size: 14px;
    }
  }
}