@import '/src/styles/import.scss';

.block_title_button__wrap {
  display: flex;
  gap: 10px;
}

.request-content__header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  align-self: stretch;
  position: relative;

  .btn-area {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    gap: 10px;

    form {
      display: flex;
      gap: 10px;

      .-gap6 {
        display: flex;
        gap: 6px;
      }
    }

    .-gap6 {
      display: flex;
      gap: 6px;
    }
  }
}

.btn__area {
  display: flex;
  align-items: center;
  gap: 20px;
}

.split-line {
  height: 1px;
  align-self: stretch;
  background: $color-black-10;
  margin: 16px 0px 0px 0px;
}