@import '/src/styles/import.scss';

.filter-row__container {
  display: flex;
  padding: 0px 40px 0px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &:not(:last-child) {
    border-right: 1px solid $color-black-10;
  }

  .filter-row-values__wrap {
    display: flex;
    padding-left: 26px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}