@import '/src/styles/import.scss';

.icon_button__container {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid $color-black-20;
  background: $color-white-100;

  &.black {
    background: $color-black-100;

    &:hover {
      img {
        filter: brightness(100%)
      }
    }
  }

  &.border-black {
    border: 1px solid $color-black-100;
  }

  &:hover {
    border: 1px solid $color-black-100;

    img {
      filter: brightness(50%);
    }
  }

  &:active {
    border: 1px solid $color-black-100;
    background: $color-black-100;

    img {
      filter: brightness(500%) contrast(80%);
    }
  }

  &:disabled {
    border: 1px solid $color-black-10;
    cursor: default;

    &:active {
      border: 1px solid $color-black-10;
      background: $color-white-100;
    }

    img {
      filter: brightness(500%) contrast(60%);
    }
  }

  &.btn-regist-cancel {
    border: 1px solid $color-black-100;
  }

  &.btn-regist-save {
    border: 1px solid $color-black-100;
    background: $color-black-100;

    &:disabled {
      background: $color-black-10;
      border: 1px solid $color-black-10;
      cursor: default;

      &:active {
        background: $color-black-10;
        border: 1px solid $color-black-10;
      }

      img {
        filter: brightness(500%);
      }
    }
  }
}