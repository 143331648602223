@import '/src/styles/import.scss';

.reset-password__body {
  display: flex;
  width: 580px;
  padding: 24px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .text__area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    .text-info {
      color: $color-black-70;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      letter-spacing: -0.28px;

      &::before {
        content: url(../../../assets/images/icons/icon_info.svg);
        padding: 0px 10px 0px 0px;
      }
    }

    .text__content {
      display: flex;
      padding: 0px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;

      ul.text-ul {
        display: flex;
        flex-direction: column;
        gap: 4px;

        li.text-li {
          display: flex;
          color: $color-black-30;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
          line-height: 20px;

          .-dot {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            min-width: 4px;
            height: 4px;
            margin-right: 10px;
            background-color: $color-black-30;
            position: relative;
            top: 7px;
          }

          .-bold {
            color: $color-black-70;
          }
        }
      }
    }
  }

  .input__area {
    display: flex;
    padding: 40px 60px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    background: $color-bg-f9;
  }

  .warning-text {
    color: $color-red-100;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.28px;
  }
}