@import '/src/styles/import.scss';

.member-item__container {
  display: flex;
  height: 42px;
  padding: 12px;
  justify-content: start;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;

  .member-item__wrap {
    display: flex;
    height: 28px;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;

    .-checkbox {
      display: flex;
      width: 28px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }

    .-name,
    .-userId {
      display: flex;
      width: 140px;
      padding: 0px 10px;
      gap: 10px;
    }

    .-email {
      display: flex;
      padding: 0px 10px;
      gap: 10px;
      flex: 1 0 0;
    }
  }
}